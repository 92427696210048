import React, { useContext, useEffect, useState } from "react"
import { Link, PageProps, navigate } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { LanguageContext } from "../contexts/Language"
import { ButtonLink } from "../components/atoms/Button"
import { SearchBar } from "../components/molecules/SearchBar"
import Icon, { Loader } from "../components/atoms/Icon"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { ThreeArrowAnimation } from "../components/molecules/ThreeArrowAnimation"
import NotFoundPageContent from "../components/molecules/404/NotFoundPageContent"

const NotFoundPage: React.FC<PageProps> = ({ data }: any) => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  return (
    <>
      {!isMount ? (
        <Layout>
          <Loader
            color="red-400"
            css={[tw`max-h-36 animate-spin mt-24 mb-28 mx-auto`]}
          />
        </Layout>
      ) : (
        <Layout>
          <NotFoundPageContent />
        </Layout>
      )}
    </>
  )
}

export default NotFoundPage
